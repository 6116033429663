import React from "react";
import { Button } from '../../globalStyles';
import {
  HeroContainer,
  Heading,
  Subtitle,
  BookingContainer
} from "./HeroSection.elements";

function HeroSection({
  lightText,
  lightTextDesc,
  headline,
  description,
  primary,
  buttonLabel
})
 
{
  return (
    <>
      <HeroContainer>
          <BookingContainer>
            <Heading lightText={lightText}>{headline}</Heading>
            <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
              <Button big fontBig primary={primary} 
              onClick={(e) => {
                e.preventDefault();
                window.location.href='https://shops.getsquire.com/book/sky-deck-los-angeles/barber/barber-matt?ig_ix=true&owner=barber';
                }}
              >
              {buttonLabel}
            </Button>
          </BookingContainer>
      </HeroContainer>
    </>
  );
}

export default HeroSection;
