import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const PhotoSection = styled.div`
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #2E3134;

  @media screen and (max-width: 960px){
    padding: 40px 0;
  }
`;

export const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`; 

export const PhotoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;


  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const PhotoCard = styled.div`
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  text-decoration: none;
  border-radius: 4px;
  display: inline-flex;
  margin-bottom: 16px;

  /* &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  } */

  @media screen and (max-width: 960px) {
    height: 100%;

    /* &:hover {
      transform: none;
    } */
  }
`;

export const StyledPhoto = styled.img`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 500px;
  align-items: center;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;