export const heroObj = {
  lightText: true,
  lightTextDesc: false,
  headline: 'SINCE 2013', 
  description: '',
  primary: false, 
  buttonLabel: 'Booking'
};

export const homeObjOne = {
  primary: false,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Celebrity Barber',
  headline: 'Leading the Generation in Hair Cuts all across the West Coast',
  description:
    'Helping individuals one cut at a time.',
  buttonLabel: '',
  imgStart: 'false',
  img: require('../../images/svg-1.svg'),
  alt: 'Booking',
  start: 'false'
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Instant Setup',
  headline: 'Extremely quick onboarding process',
  description:
    "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/svg-2.svg'),
  alt: 'Vault',
  start: ''
};

export const homeObjThree = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline:
    '',
  description:
    "",
  buttonLabel: 'View Case Study',
  imgStart: 'start',
  img: require('../../images/barber-back.jpg'),
  alt: 'Vault',
  start: 'true'
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Secure Database',
  headline: 'All your data is stored on our secure server',
  description:
    'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-3.svg'),
  alt: 'Vault',
  start: 'true'
};
